import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clsx } from 'clsx';

import { RootState } from '@/store';

import { ampli, RewardsHubOpenedProperties } from '@/ampli';
import Button from '@/components/atoms/button';
import { IconTypes } from '@/components/atoms/icon';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import { useToast } from '@/components/atoms/toast';
import { subscribeToPrivateChannels } from '@/components/contexts/pusher-events';
import ModalWrapper from '@/components/molecules/modal-wrapper';
import InventoryList from '@/components/organisms/inventory-list';
import { PowerUpInventoryUpdatedResponse } from '@/interfaces/power-ups';
import { User } from '@/interfaces/user';
import { getSelectedPickEmOptions } from '@/utilities/helpers';

import {
  getDraftingConfigDispatcher,
  getPickEmDispatcher,
  getSlipPayoutOutcomeDispatcher,
  removeAirdropOfferDispatcher,
  removePowerUpDispatcher,
  removeSelectedPickEmErrorDispatcher,
  setPowerUpOnEntryDispatcher,
} from './dispatchers';
import { useInventoryButtonData } from './selectors';

import styles from './styles.scss';

interface InventoryButtonProps {
  classNames?: {
    wrapper?: string;
    button?: string;
    icon?: string;
  };
  iconName: IconTypes;
  location: RewardsHubOpenedProperties['location'];
  size: 'large' | 'small';
  text: string | null;
  user: User;
  isAlternateProjectionsEnabled: boolean;
}

const InventoryButton = (props: InventoryButtonProps) => {
  const { classNames, iconName, location, size, text, user, isAlternateProjectionsEnabled } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const openModal = useModal();
  const openToast = useToast();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isPickEmPage = pathname.includes('pick-em');

  const { draftingConfig, isWebPick8Enabled, hasLobbyDataLoaded, payoutOutcome, pickEmEntries } =
    useInventoryButtonData();

  const payoutOutcomeIsLoading = !payoutOutcome.lastUpdated;

  const getDraftingConfig = getDraftingConfigDispatcher(dispatch);
  const getPickEm = getPickEmDispatcher(dispatch);
  const getSlipPayoutOutcome = getSlipPayoutOutcomeDispatcher(dispatch);
  const removeAirdropOffer = removeAirdropOfferDispatcher(dispatch);
  const removePowerUp = removePowerUpDispatcher(dispatch);
  const removeSelectedPickEmError = removeSelectedPickEmErrorDispatcher(dispatch);
  const setPowerUpOnEntry = setPowerUpOnEntryDispatcher(dispatch);

  useEffect(() => {
    if (hasLobbyDataLoaded) {
      const privateChannel = subscribeToPrivateChannels(user.id);
      privateChannel.bind('power_up_inventory_updated', (data: PowerUpInventoryUpdatedResponse) => {
        removePowerUp(data);

        if (pickEmEntries.selected.powerUp && isPickEmPage) {
          setPowerUpOnEntry({
            action: 'apiRemove',
            location: null,
            powerUp: null,
            reason: data.reason,
          });
        }
      });
      privateChannel.bind('airdrop_inventory_updated', (data: { airdrop_offer_id: string }) => {
        removeAirdropOffer({ airdropOfferId: data.airdrop_offer_id });
      });
    }
  }, [
    hasLobbyDataLoaded,
    isPickEmPage,
    pickEmEntries.selected.powerUp,
    removeAirdropOffer,
    removePowerUp,
    setPowerUpOnEntry,
    user.id,
  ]);

  useEffect(() => {
    if (!draftingConfig?.lastUpdated && loading) {
      // The Pick'em lobby depends on some drafting config data. If it doesn't exist,
      // we need to fetch it
      getDraftingConfig();
    }
  }, [getDraftingConfig, draftingConfig?.lastUpdated, loading]);

  useEffect(() => {
    if (!hasLobbyDataLoaded && draftingConfig.lastUpdated && loading && !isPickEmPage) {
      // If Pick'em lobby data doesn't exist, we need to fetch it
      getPickEm({
        draftingConfig,
        pickEmStateConfig: user.stateConfig.pickEm,
        roles: user.roles,
        isAlternateProjectionsEnabled,
      });
    }
  }, [
    draftingConfig,
    getPickEm,
    hasLobbyDataLoaded,
    isPickEmPage,
    loading,
    user.roles,
    user.stateConfig,
    isAlternateProjectionsEnabled,
  ]);

  useEffect(() => {
    if (
      draftingConfig.lastUpdated &&
      loading &&
      hasLobbyDataLoaded &&
      payoutOutcomeIsLoading &&
      !isPickEmPage
    ) {
      // If payout outcome doesn't exist, we need to fetch it so that adding picks from the modal
      // doesn't break the pick'em lobby and errors/updates accordingly
      const options = getSelectedPickEmOptions({
        selectedOverUnders: pickEmEntries.selected.selectedOverUnders,
        selectedRivals: pickEmEntries.selected.selectedRivals,
      });
      const { powerUp } = pickEmEntries.selected;
      getSlipPayoutOutcome({
        options,
        entryRoles: user.roles,
        isWebPick8Enabled,
        powerUpId: powerUp?.restrictions?.appliesTo === 'entrySlips' ? powerUp?.id : null,
      });
    }
  }, [
    draftingConfig.lastUpdated,
    getSlipPayoutOutcome,
    hasLobbyDataLoaded,
    isPickEmPage,
    isWebPick8Enabled,
    loading,
    payoutOutcomeIsLoading,
    pickEmEntries.selected,
    user.roles,
  ]);

  useEffect(() => {
    if (pickEmEntries.selected.error && !isPickEmPage) {
      // Currently we handle Pick'em entry toast errors in pages/pick'em. If we're not on
      // the Pick'em page, we need to handle them and show them to the user
      openToast({
        message: pickEmEntries.selected.error,
        open: true,
        customClose: removeSelectedPickEmError,
      });
    }
  }, [removeSelectedPickEmError, isPickEmPage, openToast, pickEmEntries.selected.error]);

  useEffect(() => {
    if (hasLobbyDataLoaded && !payoutOutcomeIsLoading && draftingConfig.lastUpdated && loading) {
      // This hook listens for prop updates - if loading is true and pick'em data has fully populated,
      // this hook will replace the modal with a data rich one
      setLoading(false);
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <ModalWrapper handleCloseModal={handleCloseModal} hideCloseButton>
          <InventoryList
            handleCloseModal={handleCloseModal}
            isError={payoutOutcome.selectionCount === null}
            isLoading={false}
          />
        </ModalWrapper>
      ));
    }
  }, [
    draftingConfig.lastUpdated,
    hasLobbyDataLoaded,
    loading,
    location,
    openModal,
    payoutOutcome,
    payoutOutcomeIsLoading,
  ]);

  const onInventoryButtonClick = () => {
    ampli.rewardsHubOpened({ location });

    if (hasLobbyDataLoaded && !payoutOutcomeIsLoading) {
      // If Pick'em data already exists, open the InventoryList immediately
      openModal(({ handleCloseModal }: HandleCloseModal) => (
        <ModalWrapper handleCloseModal={handleCloseModal} hideCloseButton>
          <InventoryList
            handleCloseModal={handleCloseModal}
            isError={payoutOutcome.selectionCount === null}
            isLoading={false}
          />
        </ModalWrapper>
      ));
    }

    // If Pick'em data doesn't yet exist, set loading to true and open modal with spinner
    // then let the button listen for prop updates close/reopen modal when data exists
    setLoading(true);
    openModal(
      ({ handleCloseModal }: HandleCloseModal) => (
        <ModalWrapper handleCloseModal={handleCloseModal} hideCloseButton>
          <InventoryList
            handleCloseModal={() => {
              setLoading(false);
              handleCloseModal();
            }}
            isError={false}
            isLoading
          />
        </ModalWrapper>
      ),
      {
        customClickAway: () => {
          setLoading(false);
        },
      }
    );
  };

  return (
    <div className={classNames?.wrapper}>
      <Button
        classNames={{
          button: clsx(styles.inventoryButton, styles[size], classNames?.button),
          icon: clsx(styles.icon, classNames?.icon),
        }}
        color="lightgrey" // may be overridden by classNames
        icon={!text ? iconName : null} // text takes precedence
        onClick={onInventoryButtonClick}
        size="small"
        text={text}
        type="solid"
        width="intrinsic"
      />
    </div>
  );
};

export default connect((state: RootState) => ({
  isAlternateProjectionsEnabled: state.featureFlags.webAlternateProjections,
}))(InventoryButton);
