import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import { RootState } from '@/store';

import Icon from '@/components/atoms/icon';
import OverUnderListCell from '@/components/molecules/over-under-list-cell';
import {
  AppearanceLine,
  ConstructedPickEmOverUnderLineAppearance,
  SelectedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { PickLocation } from '@/interfaces/pick-em';
import { RedeemablePowerUp } from '@/interfaces/power-ups';

import styles from './styles.scss';

export interface OverUnderListProps {
  appearanceLines: AppearanceLine[];
  constructedPickEmAppearance: ConstructedPickEmOverUnderLineAppearance;
  normalizedSearchTerm?: string;
  isAltProjectionsEnabled: boolean;
  pickLocation: PickLocation;
  powerUp: RedeemablePowerUp;
  selectedLine?: { lineId: string; optionDisplay: string };
  selectedOverUnders: SelectedOverUnder[];
  // We only pass in removeOverUnder from the <ReviewSection />
}

const DISPLAY_AMOUNT = 4;
const MAX_DISPLAY_AMOUNT = 5;

const OverUnderList = (props: OverUnderListProps) => {
  const {
    appearanceLines,
    constructedPickEmAppearance,
    normalizedSearchTerm,
    isAltProjectionsEnabled,
    pickLocation,
    powerUp,
    selectedLine,
    selectedOverUnders,
  } = props;
  const [expanded, setExpanded] = useState(false);

  const overUnderListCells = useMemo(() => {
    let appearanceLinesToRender: AppearanceLine[] = [];
    if (selectedLine && pickLocation === PickLocation.REVIEW) {
      appearanceLinesToRender = appearanceLines.filter((appearanceLine) => {
        if (!appearanceLine.splitLine) {
          return appearanceLine.id === selectedLine.lineId;
        }
        return (
          appearanceLine.splitLine &&
          appearanceLine.id === selectedLine.lineId &&
          appearanceLine.option === selectedLine.optionDisplay.toLowerCase()
        );
      });
    } else {
      appearanceLinesToRender = appearanceLines;
    }

    /** Remove duplicate appearance lines if alts is enabled.
     * Explanation with an example:
     * Say Jordan has a projection: 25.5 points and this has alts.
     * Say the default line is 25.5 points `Higher` for 0.6x.
     * It could have a corresponding `Lower` line for 1.4x.
     * Scenario 1: When alts are NOT enabled, we show both lines on two separate rows:
     ** Jordan 25.5 points --- Higher 0.6x
     ** Jordan 25.5 points ---  Lower 1.4x
     * Scenario 2: When alts are enabled, we only want to show one row
     ** Jordan 25.5 points --- Higher 0.6x (or whatever the current selection is)
     * `Lower` (and other options) would be accessible only through the alt projections
     * modal. So we want to hide the duplicate appearance line for 25.5 points
     * in this case.
     */
    if (constructedPickEmAppearance.hasAlts && isAltProjectionsEnabled) {
      const appearanceLineIds: string[] = appearanceLinesToRender.map(
        (appearanceLine) => appearanceLine.id
      );
      appearanceLinesToRender = appearanceLinesToRender.filter(
        (appearanceLine, index) => appearanceLineIds.indexOf(appearanceLine.id) === index
      );
    }

    return appearanceLinesToRender.map((appearanceLine) => {
      const actualLineId = appearanceLine.id;

      const selectedOverUnder = selectedOverUnders?.find(
        (sOU) => sOU.overUnderLineId === actualLineId
      );

      return (
        <OverUnderListCell
          key={appearanceLine.id + appearanceLine.option}
          appearanceLine={appearanceLine}
          constructedPickEmAppearance={constructedPickEmAppearance}
          pickLocation={pickLocation}
          powerUp={powerUp}
          selectedOverUnder={selectedOverUnder}
        />
      );
    });
  }, [
    constructedPickEmAppearance,
    appearanceLines,
    pickLocation,
    powerUp,
    selectedLine,
    selectedOverUnders,
    isAltProjectionsEnabled,
  ]);

  const numberOfProjections = overUnderListCells.length;

  const overUnderListEl = useMemo(() => {
    if (pickLocation === PickLocation.PLAYER_CARD || normalizedSearchTerm) {
      return overUnderListCells;
    }

    // If there are only 5 projections show all 5 and don't show the 'more picks' button
    // If there are more than 5 projections only show 4 and show the 'more picks' button
    const displayAmt =
      overUnderListCells.length > MAX_DISPLAY_AMOUNT ? DISPLAY_AMOUNT : MAX_DISPLAY_AMOUNT;

    const cellsToShow = overUnderListCells.slice(0, displayAmt).map((cell) => cell);
    const remainingCellsToShow = expanded ? overUnderListCells.slice(displayAmt) : [];

    return (
      <>
        {cellsToShow}
        <AnimatePresence>
          {expanded && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={{ duration: 0.25 }}
              style={{ overflow: 'hidden' }}
            >
              {remainingCellsToShow.map((cell, index) => (
                <motion.div
                  key={cell.key}
                  initial={{ opacity: expanded ? 0 : 1, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.15, delay: index * 0.05 }}
                >
                  {cell}
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  }, [expanded, pickLocation, overUnderListCells, normalizedSearchTerm]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (expanded) {
        setExpanded(false);
        // waiting on the bug fix with ampli not pulling in all events
        // ampli.collapsePlayerLobbyCard({
        //   sport_name: constructedPickEmAppearance.sport.name,
        //   player_name: langHelper.getPlayerFullName(constructedPickEmAppearance.player),
        //   player_id: constructedPickEmAppearance.id,
        //   position_id: constructedPickEmAppearance.positionId,
        // });
      } else {
        setExpanded(true);
        // waiting on the bug fix with ampli not pulling in all events
        // ampli.expandPlayerLobbyCard({
        //   sport_name: constructedPickEmAppearance.sport.name,
        //   player_name: langHelper.getPlayerFullName(constructedPickEmAppearance.player),
        //   player_id: constructedPickEmAppearance.id,
        //   position_id: constructedPickEmAppearance.positionId,
        // });
      }
    },
    [expanded]
  );

  return (
    <>
      {overUnderListEl}
      {pickLocation !== PickLocation.PLAYER_CARD &&
        !normalizedSearchTerm &&
        numberOfProjections > MAX_DISPLAY_AMOUNT && (
          <button className={styles.toggleButton} onClick={onClick}>
            {expanded ? (
              <span className={styles.toggleSpan}>Fewer picks</span>
            ) : (
              <span className={styles.toggleSpan}>
                {`More picks (${numberOfProjections - DISPLAY_AMOUNT})`}
              </span>
            )}
            <Icon className={styles.toggleArrow} name={expanded ? 'upCaret' : 'downCaret'} />
          </button>
        )}
    </>
  );
};

export default connect((state: RootState) => ({
  normalizedSearchTerm: state.pickEmOverUnder.normalizedSearchTerm,
  powerUp: state.pickEmEntries.selected.powerUp,
  selectedOverUnders: state.pickEmEntries.selected.selectedOverUnders,
  isAltProjectionsEnabled: state.featureFlags.webAlternateProjections,
}))(OverUnderList);
