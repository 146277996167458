/* eslint-disable camelcase */

import { pickEmOverUnderAppearancesConstructor } from '@/store/constructors/pick-em-over-under-appearances-constructor';
import appearanceAdapter from '@/store/modules/constructed-slates/adapters/appearance';
import playerAdapter from '@/store/modules/constructed-slates/adapters/player';
import matchAdapter from '@/store/modules/matches/adapters/match';

import {
  ConstructedPickEmOverUnderLineAppearance,
  PickEmOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { DraftingConfig, StateConfig } from '@/interfaces/drafting-config';
import { StatLineUpdateResponse } from '@/interfaces/live';
import {
  AlternateProjection,
  AlternateProjectionsResponse,
  FeaturedLobbyFavoritePlayer,
  FeaturedLobbyResponse,
  FeaturedOverUnderLinesResponse,
  OverUnderLines,
  OverUnderLinesResponse,
  OverUnderLineSwapResponse,
  OverUnderOption,
  RivalLinesResponse,
} from '@/interfaces/pick-em';
import { PowerUpInventoryUpdatedResponse, RedeemablePowerUp } from '@/interfaces/power-ups';
import { Role } from '@/interfaces/user';
import { arrayToObjectIdKeys, normalizeText } from '@/utilities/helpers';

import {
  ACCEPT_OVER_UNDER_LINE_SWAP,
  acceptOverUnderLineSwap,
  ADD_FAVORITE_PICK_EM_PLAYER,
  addFavoritePickEmPlayer,
  CHOOSE_ALTERNATE_PROJECTION,
  chooseAlternateProjection as chooseAlternateProjectionAction,
  CLEAR_ALTERNATE_PROJECTIONS_FROM_LINE,
  clearAlternateProjectionsFromLine,
  REMOVE_AIRDROP_OFFER,
  REMOVE_FAVORITE_PICK_EM_PLAYER,
  REMOVE_PICK_EM_OVER_UNDER_LINE,
  REMOVE_POWER_UP,
  removeAirdropOffer as removeAirdropOfferAction,
  removeFavoritePickEmPlayer,
  removePickEmOverUnderLine,
  removePowerUp as removePowerUpAction,
  SET_ALTERNATE_PROJECTIONS,
  SET_PICK_EM_FEATURED_LOBBY,
  SET_PICK_EM_OVER_UNDER_LINES,
  SET_SEARCH_TERM,
  setAlternateProjections as setAlternateProjectionsAction,
  setPickEmFeaturedLobby as setPickEmFeaturedLobbyAction,
  setPickEmOverUnderLines,
  setSearchTerm,
  SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE,
  SWAP_PICK_EM_OVER_UNDER_LINE,
  swapFinishedPickEmOverUnderLine,
  swapPickEmOverUnderLine,
  UPDATE_PICK_EM_LIVE_EVENT_STAT,
  UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS,
  updatePickEmLiveEventStat,
  updatePickEmOverUnderLineStatus,
} from '../actions';
import alternateProjectionAdapter from '../adapters/alternate-projection';
import featuredLobbyAdapter from '../adapters/featured-lobby';
import overUnderLineAdapter from '../adapters/over-under-lines';
import soloGameAdapter from '../adapters/solo-game';

import {
  convertToObjects,
  getLinesByStateConfig,
  removeDuplicateAppearances,
} from './helpers/over-under-lines';

type State = PickEmOverUnder;

type PickEmOverUnderActions = ReturnType<
  | typeof setPickEmOverUnderLines
  | typeof setPickEmFeaturedLobbyAction
  | typeof acceptOverUnderLineSwap
  | typeof removePickEmOverUnderLine
  | typeof addFavoritePickEmPlayer
  | typeof removeFavoritePickEmPlayer
  | typeof updatePickEmOverUnderLineStatus
  | typeof updatePickEmLiveEventStat
  | typeof swapFinishedPickEmOverUnderLine
  | typeof swapPickEmOverUnderLine
  | typeof setSearchTerm
  | typeof removeAirdropOfferAction
  | typeof addFavoritePickEmPlayer
  | typeof removePowerUpAction
  | typeof setAlternateProjectionsAction
  | typeof chooseAlternateProjectionAction
  | typeof clearAlternateProjectionsFromLine
>;

export const initialState: PickEmOverUnder = {
  overUnderLines: {},
  appearances: null,
  games: null,
  players: null,
  soloGames: null,
  constructedAppearances: null,
  hasLobbyDataLoaded: false,
  comingSoonBoosts: null,
  featuredLobby: {
    airdrops: null,
    appearances: null,
    overUnderLines: null,
    constructedAppearances: null,
    constructedPromoAppearances: null,
    constructedRivalLines: null,
    sections: null,
    pickemPacks: null,
    playerGroups: null,
    powerUps: null,
    rivalLines: null,
    soloGames: null,
    games: null,
    players: null,
    favoritePlayers: null,
  },
  normalizedSearchTerm: '',
};

const set = (
  state: PickEmOverUnder,
  {
    preGameOverUnderLines,
    liveOverUnderLines,
    featuredOverUnders,
    draftingConfig,
    pickEmStateConfig,
    roles,
  }: {
    preGameOverUnderLines: OverUnderLinesResponse;
    liveOverUnderLines: OverUnderLinesResponse;
    featuredOverUnders: FeaturedOverUnderLinesResponse;
    draftingConfig: DraftingConfig;
    pickEmStateConfig: StateConfig['pickEm'];
    roles: Role[];
  }
): PickEmOverUnder => {
  const isAdmin = roles.includes('admin');

  const overUnderLinesResponse = [
    ...featuredOverUnders.over_under_lines,
    ...preGameOverUnderLines.over_under_lines,
    ...liveOverUnderLines.over_under_lines,
  ];
  const appearancesResponse = [
    ...featuredOverUnders.appearances,
    ...preGameOverUnderLines.appearances,
    ...liveOverUnderLines.appearances,
  ];
  const gamesResponse = [
    ...featuredOverUnders.games,
    ...preGameOverUnderLines.games,
    ...liveOverUnderLines.games,
  ];
  const playersResponse = [
    ...featuredOverUnders.players,
    ...preGameOverUnderLines.players,
    ...liveOverUnderLines.players,
  ];
  const soloGamesResponse = [
    ...featuredOverUnders.solo_games,
    ...preGameOverUnderLines.solo_games,
    ...liveOverUnderLines.solo_games,
  ];

  const overUnderLinesArray = getLinesByStateConfig(
    overUnderLinesResponse.map(overUnderLineAdapter),
    pickEmStateConfig,
    isAdmin
  );

  const appearances = removeDuplicateAppearances(appearancesResponse.map(appearanceAdapter));

  const { games, soloGames, players, overUnderLines } = convertToObjects(
    gamesResponse.map(matchAdapter),
    soloGamesResponse.map(soloGameAdapter),
    playersResponse.map((player) => playerAdapter(player)),
    overUnderLinesArray
  );

  const { teams, lineupStatuses, sports } = draftingConfig;

  const constructedAppearances = appearances
    ? pickEmOverUnderAppearancesConstructor({
        visibleSportIds: pickEmStateConfig.visibleSports,
        isAdmin,
        appearances,
        games,
        lineupStatuses,
        overUnderLines: overUnderLinesArray,
        players,
        soloGames,
        sports,
        teams,
      })
    : null;

  return {
    ...state,
    overUnderLines,
    appearances,
    games,
    players,
    soloGames,
    constructedAppearances,
    hasLobbyDataLoaded: true,
    normalizedSearchTerm: '',
  };
};

const setPickEmFeaturedLobby = (
  state: PickEmOverUnder,
  {
    data,
    pickEmStateConfig,
    draftingConfig,
    rivalLines,
    roles,
  }: {
    data: FeaturedLobbyResponse;
    pickEmStateConfig: StateConfig['pickEm'];
    draftingConfig: DraftingConfig;
    rivalLines: RivalLinesResponse['rival_lines'];
    roles: Role[];
  }
): PickEmOverUnder => {
  const isAdmin = roles.includes('admin');

  const {
    appearances: appearancesResponse,
    games: gamesResponse,
    over_under_lines,
    players: playersResponse,
    solo_games: soloGamesResponse,
  } = data;

  const overUnderLinesArray = getLinesByStateConfig(
    over_under_lines.map(overUnderLineAdapter),
    pickEmStateConfig,
    isAdmin
  );

  const uniqueAppearances = removeDuplicateAppearances(appearancesResponse.map(appearanceAdapter));

  const { games, soloGames, players } = convertToObjects(
    gamesResponse.map(matchAdapter),
    soloGamesResponse.map(soloGameAdapter),
    playersResponse.map((player) => playerAdapter(player)),
    overUnderLinesArray
  );

  const { teams, lineupStatuses, sports } = draftingConfig;

  const constructedAppearances = uniqueAppearances
    ? pickEmOverUnderAppearancesConstructor({
        visibleSportIds: pickEmStateConfig.visibleSports,
        isAdmin,
        appearances: uniqueAppearances,
        games,
        lineupStatuses,
        overUnderLines: overUnderLinesArray,
        players,
        soloGames,
        sports,
        teams,
      })
    : null;

  const constructedPromoAppearances = Object.values(constructedAppearances)
    .map((constructedAppearance) => {
      const boostedLineIds = overUnderLinesArray
        .filter(
          (oUL) =>
            oUL?.overUnder?.appearanceStat.appearanceId === constructedAppearance.id &&
            oUL.overUnder.boost
        )
        .map((line) => line.id);

      return {
        id: constructedAppearance.id,
        boostedLineIds,
        sport: constructedAppearance.sport,
      };
    })
    .filter((cPA) => cPA.boostedLineIds.length);

  const featuredLobbyContent = featuredLobbyAdapter(data, rivalLines);

  return {
    ...state,
    featuredLobby: {
      ...featuredLobbyContent,
      constructedAppearances,
      constructedPromoAppearances,
    },
  };
};

const setAlternateProjections = (
  state: PickEmOverUnder,
  {
    altProjectionsResponse,
    alternateLineIdToChoose,
    alternateOptionToChoose,
    constructedAppearanceId,
  }: {
    altProjectionsResponse: AlternateProjectionsResponse;
    alternateLineIdToChoose?: string;
    alternateOptionToChoose?: OverUnderOption;
    constructedAppearanceId?: string;
  }
): PickEmOverUnder => {
  if (!altProjectionsResponse.projections.length) {
    // If the alt projection in the localStorage is expired, we may not even get
    // any alt projections back from the API.
    return state;
  }

  const altProjections = altProjectionsResponse.projections.map(alternateProjectionAdapter);

  const mainLineId = altProjections.filter((altProjection) => altProjection.isMain)[0].id;

  const overUnderLine = state.overUnderLines[mainLineId];

  // We create a newOverUnderLines array here and basically copy the main line
  // and replace the `id`, `options` and `statValue` from the corresponding
  // alternate projection. This way we end up with completely new overUnderLines
  // for the alternate projections but with the same data shape and information
  // to distinguish between main lines and alt lines, which is helpful when
  // using these lines in the <OverUnderListCell /> or <OptionsWrapper />, and will
  // also be helpful when the pusher events try to update these lines in the store.
  // Note: We also add these lines to overUnderLine.alternateProjections so we can
  // easily access them together in the alts modal when needed. Not saving them together
  // would mean we need to access all overUnderLines and filter out the alts for that
  // specific line/stat which is not ideal.
  const newOverUnderLinesArray = altProjections.map((altProjection) => {
    const { id, options, statValue } = altProjection;

    const splitLine = altProjection.options.some((option) => option.payoutMultiplier !== 1);

    return {
      ...overUnderLine,
      id,
      options,
      statValue,
      splitLine,
      isMain: altProjection.id === mainLineId,
      mainLineId,
    };
  });

  const newOverUnderLines = arrayToObjectIdKeys(newOverUnderLinesArray);

  const constructedAppearance = state.constructedAppearances[constructedAppearanceId];
  const constructedFeaturedAppearance =
    state.featuredLobby.constructedAppearances[constructedAppearanceId];

  const getUpdatedAppearanceLines = (cA: ConstructedPickEmOverUnderLineAppearance) => {
    if (cA) {
      return cA.appearanceLines.map((appearanceLine) => {
        if (appearanceLine.id === mainLineId) {
          return {
            ...appearanceLine,
            id: alternateLineIdToChoose,
            splitLine: alternateOptionToChoose.payoutMultiplier !== 1,
            option: alternateOptionToChoose.choice,
          };
        }
        return appearanceLine;
      });
    }
    return [];
  };

  const newAppearanceLines = getUpdatedAppearanceLines(constructedAppearance);
  const newFeaturedAppearanceLines = getUpdatedAppearanceLines(constructedFeaturedAppearance);

  return {
    ...state,
    constructedAppearances: {
      ...state.constructedAppearances,
      ...(constructedAppearance && {
        [constructedAppearanceId]: {
          ...constructedAppearance,
          appearanceLines: newAppearanceLines,
        },
      }),
    },
    featuredLobby: {
      ...state.featuredLobby,
      constructedAppearances: {
        ...state.featuredLobby.constructedAppearances,
        ...(constructedFeaturedAppearance && {
          [constructedAppearanceId]: {
            ...constructedFeaturedAppearance,
            appearanceLines: newFeaturedAppearanceLines,
          },
        }),
      },
    },
    overUnderLines: {
      ...state.overUnderLines,
      ...newOverUnderLines,
      [mainLineId]: {
        ...overUnderLine,
        ...(!alternateLineIdToChoose && { alternateProjections: altProjections }),
        isMain: true,
        mainLineId,
      },
    },
  };
};

const chooseAlternateProjection = (
  state: PickEmOverUnder,
  {
    selectedAlternateLineId,
    selectedOptionId,
  }: {
    selectedAlternateLineId: string;
    selectedOptionId: string;
  }
): PickEmOverUnder => {
  const overUnderLine = state.overUnderLines[selectedAlternateLineId];
  if (!overUnderLine) {
    return state;
  }

  const mainLineId = overUnderLine.isMain ? selectedAlternateLineId : overUnderLine.mainLineId;
  const allAlternateLineIds = state.overUnderLines[mainLineId].alternateProjections.map(
    (alt) => alt.id
  );
  const selectedAlternateLine = state.overUnderLines[selectedAlternateLineId];
  const { appearanceId } = overUnderLine.overUnder.appearanceStat;
  const constructedAppearance = state.constructedAppearances[appearanceId];
  const featuredConstructedAppearance = state.featuredLobby.constructedAppearances[appearanceId];

  const getUpdatedAppearanceLines = (
    cA: ConstructedPickEmOverUnderLineAppearance,
    lineId: string,
    splitLine: boolean,
    option: 'higher' | 'lower' | null
  ) => {
    if (cA) {
      return cA.appearanceLines.map((appearanceLine) => {
        if (allAlternateLineIds.includes(appearanceLine.id)) {
          return {
            ...appearanceLine,
            id: lineId,
            option,
            splitLine,
          };
        }
        return appearanceLine;
      });
    }
    return [];
  };

  if (selectedAlternateLine && selectedOptionId) {
    const selectedOption = selectedAlternateLine.options.find(
      (option) => option.id === selectedOptionId
    );

    const newAppearanceLines = getUpdatedAppearanceLines(
      constructedAppearance,
      selectedAlternateLineId,
      selectedOption.payoutMultiplier !== 1,
      selectedOption.choiceDisplay === 'Higher' ? 'higher' : 'lower'
    );

    const newFeaturedAppearanceLines = getUpdatedAppearanceLines(
      featuredConstructedAppearance,
      selectedAlternateLineId,
      selectedOption.payoutMultiplier !== 1,
      selectedOption.choiceDisplay === 'Higher' ? 'higher' : 'lower'
    );

    return {
      ...state,
      constructedAppearances: {
        ...state.constructedAppearances,
        [appearanceId]: {
          ...constructedAppearance,
          appearanceLines: newAppearanceLines,
        },
      },
      featuredLobby: {
        ...state.featuredLobby,
        constructedAppearances: {
          ...state.featuredLobby.constructedAppearances,
          ...(featuredConstructedAppearance && {
            [appearanceId]: {
              ...featuredConstructedAppearance,
              appearanceLines: newFeaturedAppearanceLines,
            },
          }),
        },
      },
    };
  }

  // Ideally, this action would not be called without a selectedAlternateLine and selectedOptionId.
  // But in case it is, we return the previously selected line, defaulting to the first option if
  // it's a scorcher so that the ui does not display a blank space instead of the options.
  const newAppearanceLines = getUpdatedAppearanceLines(
    constructedAppearance,
    overUnderLine.id,
    overUnderLine.splitLine,
    overUnderLine.splitLine
      ? ((overUnderLine.overUnder.optionPriority === 'none'
          ? overUnderLine.options[0].choice
          : overUnderLine.overUnder.optionPriority) as 'higher' | 'lower' | null)
      : null
  );

  const newFeaturedAppearanceLines = getUpdatedAppearanceLines(
    featuredConstructedAppearance,
    overUnderLine.id,
    overUnderLine.splitLine,
    overUnderLine.splitLine
      ? ((overUnderLine.overUnder.optionPriority === 'none'
          ? overUnderLine.options[0].choice
          : overUnderLine.overUnder.optionPriority) as 'higher' | 'lower' | null)
      : null
  );

  return {
    ...state,
    constructedAppearances: {
      ...state.constructedAppearances,
      [appearanceId]: {
        ...constructedAppearance,
        appearanceLines: newAppearanceLines,
      },
    },
    featuredLobby: {
      ...state.featuredLobby,
      constructedAppearances: {
        ...state.featuredLobby.constructedAppearances,
        ...(featuredConstructedAppearance && {
          [appearanceId]: {
            ...featuredConstructedAppearance,
            appearanceLines: newFeaturedAppearanceLines,
          },
        }),
      },
    },
  };
};

const clearAlternateProjectionsFromOverUnderLine = (
  state: PickEmOverUnder,
  { overUnderLineId }: { overUnderLineId: string }
): PickEmOverUnder => {
  return {
    ...state,
    overUnderLines: {
      ...state.overUnderLines,
      [overUnderLineId]: {
        ...state.overUnderLines[overUnderLineId],
        alternateProjections: null,
      },
    },
  };
};

const remove = (state: PickEmOverUnder, { data }: { data: string[] }): PickEmOverUnder => {
  const newOverUnderLines = data.reduce((acc, overUnderId) => {
    if (state.overUnderLines[overUnderId]) {
      // line exists
      acc[overUnderId] = {
        ...state.overUnderLines[overUnderId],
        status: 'removed',
      };
    }
    return acc;
  }, {} as OverUnderLines);

  return {
    ...state,
    overUnderLines: {
      ...state.overUnderLines,
      ...newOverUnderLines,
    },
  };
};

const updateLiveOverUnderLineStatus = (
  state: PickEmOverUnder,
  {
    statusType,
    overUnderLineIds,
  }: { statusType: 'suspended' | 'unsuspended'; overUnderLineIds: string[] }
): PickEmOverUnder => {
  const newOverUnderLines = overUnderLineIds.reduce((acc, overUnderId) => {
    if (state.overUnderLines[overUnderId]) {
      // line exists
      acc[overUnderId] = {
        ...state.overUnderLines[overUnderId],
        status: statusType === 'suspended' ? 'suspended' : 'active',
      };
    }
    return acc;
  }, {} as OverUnderLines);

  return {
    ...state,
    overUnderLines: {
      ...state.overUnderLines,
      ...newOverUnderLines,
    },
  };
};

const swapOverUnderLine = (
  state: PickEmOverUnder,
  { data }: { data: OverUnderLineSwapResponse[] }
): PickEmOverUnder => {
  const newConstructedAppearances = { ...state.constructedAppearances };
  const overUnderLines = { ...state.overUnderLines };

  if (overUnderLines && Object.keys(overUnderLines).length > 0) {
    data.forEach((oULSwapResponse) => {
      if (!state.overUnderLines[oULSwapResponse.old_id]) {
        return;
      }

      if (
        !newConstructedAppearances[
          overUnderLines[oULSwapResponse.old_id].overUnder.appearanceStat.appearanceId
        ]
      ) {
        /** If there is no appearance found with this id, it means that it has
         * probably been filtered out based on what sports/stats are supposed to be
         * visible to the user. In such a case, we can simply skip updating the
         * overUnderLine.
         */
        return;
      }

      const oldOverUnderLine = overUnderLines[oULSwapResponse.old_id];
      const { options: oldLineOptions, statValue: oldStatValue } = oldOverUnderLine;

      const {
        new_id: newId,
        new_stat: newStat,
        old_stat: oldStat,
        old_id: oldId,
        options: newOptions,
        multiplier_swap: multiplierSwap,
      } = oULSwapResponse;

      const updatedOptions = newOptions.map((newOption) => ({
        id: newOption.id,
        choice: newOption.choice,
        choiceDisplay: newOption.choice_display,
        overUnderLineId: newOption.over_under_line_id,
        payoutMultiplier: Number(newOption.payout_multiplier),
        type: newOption.type,
      }));

      if (oldOverUnderLine.isMain) {
        if (oldOverUnderLine?.alternateProjections?.length > 0) {
          const newAlternateProjections: AlternateProjection[] = [];

          oldOverUnderLine.alternateProjections.forEach((altProjection) => {
            /**
             * If this over under line has alt projections, we've probably also
             * saved those corresponding over under lines in the main overUnderLines
             * object. Here, we are updating the main line id of those alt projections
             * to the new id.
             *  */
            if (overUnderLines[altProjection.id]) {
              overUnderLines[altProjection.id] = {
                ...overUnderLines[altProjection.id],
                mainLineId: newId,
              };
              newAlternateProjections.push(altProjection);
            }
          });

          overUnderLines[oldId] = {
            ...oldOverUnderLine,
            id: oldId,
            statValue: oldStat,
            options: oldLineOptions,
            needsAcceptanceForStatChange: multiplierSwap || newStat !== oldStat,
            oldOverUnderLineId: oldId,
            oldStatValue,
            oldLineOptions,
            status: 'active',
            alternateProjections: newAlternateProjections,
          };
        }
      }

      // Update the key of the object, changing it from the old id to the new id
      delete Object.assign(overUnderLines, { [newId]: overUnderLines[oldId] })[oldId];
      // Update the rest of the object with the updated values
      overUnderLines[newId] = {
        ...oldOverUnderLine,
        id: newId,
        statValue: newStat,
        options: updatedOptions,
        oldOverUnderLineId: oldId,
        oldStatValue,
        needsAcceptanceForStatChange: multiplierSwap || newStat !== oldStat,
        oldLineOptions: multiplierSwap && oldLineOptions,
        status: 'active',
      };
    });

    data.forEach((oULSwapResponse) => {
      if (!state.overUnderLines[oULSwapResponse.old_id]) {
        // Since we do not swap overUnderLines if we don't find a match,
        // we will also skip swapping the lines in the respective
        // constructedAppearances and return early.
        return;
      }

      const appearanceIdForSwap =
        overUnderLines[oULSwapResponse.new_id]?.overUnder.appearanceStat.appearanceId ??
        overUnderLines[oULSwapResponse.old_id]?.overUnder.appearanceStat.appearanceId;

      if (!newConstructedAppearances[appearanceIdForSwap]) {
        /** If there is no appearance found with this id, it means that it has
         * probably been filtered out based on what sports/stats are supposed to be
         * visible to the user. In such a case, we can simply skip updating the
         * constructedAppearances.
         */
        return;
      }

      const newAppearanceLinesForThisAppearance = newConstructedAppearances[
        appearanceIdForSwap
      ].appearanceLines.map((appearanceLine) => {
        if (appearanceLine.id === oULSwapResponse.old_id) {
          return {
            ...appearanceLine,
            id: oULSwapResponse.new_id,
          };
        }
        return appearanceLine;
      });

      newConstructedAppearances[appearanceIdForSwap] = {
        ...newConstructedAppearances[appearanceIdForSwap],
        appearanceLines: newAppearanceLinesForThisAppearance,
      };
    });
  }

  return {
    ...state,
    overUnderLines,
    constructedAppearances: {
      ...state.constructedAppearances,
      ...newConstructedAppearances,
    },
  };
};

const swapFinishedOverUnderLine = (
  state: PickEmOverUnder,
  {
    overUnderLineIds,
  }: // we're just cleaning up the oldStat and oldId stuff
  { overUnderLineIds: string[] }
): PickEmOverUnder => {
  const newOverUnderLines = Object.keys(state.overUnderLines).reduce((result, overUnderLineId) => {
    if (overUnderLineIds.includes(overUnderLineId)) {
      // eslint-disable-next-line no-param-reassign
      result[overUnderLineId] = {
        ...state.overUnderLines[overUnderLineId],
        oldOverUnderLineId: null,
        oldStatValue: null,
        oldLineOptions: null,
      };
    }
    if (!overUnderLineIds.includes(overUnderLineId)) {
      // eslint-disable-next-line no-param-reassign
      result[overUnderLineId] = state.overUnderLines[overUnderLineId];
    }
    return result;
  }, {} as OverUnderLines);

  return {
    ...state,
    overUnderLines: newOverUnderLines,
  };
};

export const updateLiveEventStat = (
  state: PickEmOverUnder,
  {
    data,
    appearanceId,
  }: {
    data: StatLineUpdateResponse;
    appearanceId: string;
  }
) => {
  const constructedAppearances = { ...state.constructedAppearances };
  const { owner_id: ownerId, scores, data: statLineData } = data.stat_line;

  const constructedAppearance = constructedAppearances[appearanceId];

  // as we map through the overUnders, we only update the OverUnder we're looking for by
  // checking for its appearance ID
  const newOverUnderLinesArray = constructedAppearance.appearanceLines.map((appearanceLine) => {
    const lineId = appearanceLine.id;
    const overUnderLine = state.overUnderLines[lineId];
    const { appearanceStat, scoringTypeId } = overUnderLine.overUnder;

    // update only the relevant line's appearance stat
    if (appearanceStat.appearanceId === ownerId) {
      const updatedLiveEventStat =
        appearanceStat.stat === 'fantasy_points'
          ? scores.find((score) => score.scoring_type_id === scoringTypeId)?.points
          : statLineData[appearanceStat.stat];

      // Replace the liveEventStat with the updated value
      // we default to the current oUL's liveEventStat value in case of any errors
      const newLiveEventStat =
        overUnderLine.liveEventStat === null
          ? null
          : updatedLiveEventStat || overUnderLine.liveEventStat;

      return {
        ...overUnderLine,
        liveEventStat: newLiveEventStat,
      };
    }

    return overUnderLine;
  });

  const newOverUnderLines: OverUnderLines = arrayToObjectIdKeys(newOverUnderLinesArray);

  return {
    ...state,
    overUnderLines: {
      ...state.overUnderLines,
      ...newOverUnderLines,
    },
  };
};

export const acceptOverUnderSwap = (
  state: PickEmOverUnder,
  {
    overUnderLineIds,
  }: {
    overUnderLineIds: string[];
  }
): PickEmOverUnder => {
  const newOverUnderLines = overUnderLineIds.reduce((acc, overUnderId) => {
    if (state.overUnderLines[overUnderId]) {
      // line exists
      acc[overUnderId] = {
        ...state.overUnderLines[overUnderId],
        needsAcceptanceForStatChange: false,
      };
    }
    return acc;
  }, {} as OverUnderLines);

  return {
    ...state,
    overUnderLines: {
      ...state.overUnderLines,
      ...newOverUnderLines,
    },
  };
};

const setSearchValue = (state: PickEmOverUnder, { searchTerm }: { searchTerm: string }): State => {
  return {
    ...state,
    normalizedSearchTerm: normalizeText(searchTerm),
  };
};

const addFavoritePlayer = (
  state: PickEmOverUnder,
  { player }: { player: FeaturedLobbyFavoritePlayer }
): PickEmOverUnder => {
  const currentFavoritePlayers = state.featuredLobby.favoritePlayers;
  currentFavoritePlayers.push(player);

  return {
    ...state,
    featuredLobby: {
      ...state.featuredLobby,
      favoritePlayers: [...currentFavoritePlayers],
    },
  };
};

const removeFavoritePlayer = (
  state: PickEmOverUnder,
  { playerId }: { playerId: string }
): PickEmOverUnder => {
  const currentFavoritePlayers = state.featuredLobby.favoritePlayers;

  const newFavoritePlayers = currentFavoritePlayers.filter(
    (favoritePlayer) => favoritePlayer.id !== playerId
  );

  return {
    ...state,
    featuredLobby: {
      ...state.featuredLobby,
      favoritePlayers: newFavoritePlayers,
    },
  };
};

const removePowerUp = (
  state: PickEmOverUnder,
  { data }: { data: PowerUpInventoryUpdatedResponse }
) => {
  let newPowerUps: RedeemablePowerUp[] = [];
  if (!data.power_up_uses?.length) {
    newPowerUps = state.featuredLobby.powerUps.filter((powerUp) => powerUp.id !== data.power_up_id);
  } else {
    newPowerUps = state.featuredLobby.powerUps.map((powerUp) => {
      if (powerUp.id === data.power_up_id) {
        return {
          ...powerUp,
          uses: data.power_up_uses.map((use) => ({
            expiresAt: use.expires_at,
            id: use.id,
          })),
          expires_at: data.expiration,
        };
      }
      return powerUp;
    });
  }

  return {
    ...state,
    featuredLobby: {
      ...state.featuredLobby,
      powerUps: newPowerUps,
    },
  };
};

const removeAirdropOffer = (
  state: PickEmOverUnder,
  { airdropOfferId }: { airdropOfferId: string }
): PickEmOverUnder => {
  const currentAirdrops = state.featuredLobby.airdrops;
  const newAirdrops = currentAirdrops.filter((airdropOffer) => airdropOffer.id !== airdropOfferId);

  return {
    ...state,
    featuredLobby: {
      ...state.featuredLobby,
      airdrops: newAirdrops,
    },
  };
};

export const pickEmOverUnderLineReducer = (
  state: State = initialState,
  action: PickEmOverUnderActions = {} as PickEmOverUnderActions
): State => {
  switch (action.type) {
    case SET_PICK_EM_OVER_UNDER_LINES:
      return set(state, action.payload);
    case SET_PICK_EM_FEATURED_LOBBY:
      return setPickEmFeaturedLobby(state, action.payload);
    case SET_ALTERNATE_PROJECTIONS:
      return setAlternateProjections(state, action.payload);
    case CHOOSE_ALTERNATE_PROJECTION:
      return chooseAlternateProjection(state, action.payload);
    case CLEAR_ALTERNATE_PROJECTIONS_FROM_LINE:
      return clearAlternateProjectionsFromOverUnderLine(state, action.payload);
    case REMOVE_PICK_EM_OVER_UNDER_LINE:
      return remove(state, action.payload);
    case UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS:
      return updateLiveOverUnderLineStatus(state, action.payload);
    case SWAP_PICK_EM_OVER_UNDER_LINE:
      return swapOverUnderLine(state, action.payload);
    case SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE:
      return swapFinishedOverUnderLine(state, action.payload);
    case UPDATE_PICK_EM_LIVE_EVENT_STAT:
      return updateLiveEventStat(state, action.payload);
    case ACCEPT_OVER_UNDER_LINE_SWAP:
      return acceptOverUnderSwap(state, action.payload);
    case SET_SEARCH_TERM:
      return setSearchValue(state, action.payload);
    case ADD_FAVORITE_PICK_EM_PLAYER:
      return addFavoritePlayer(state, action.payload);
    case REMOVE_FAVORITE_PICK_EM_PLAYER:
      return removeFavoritePlayer(state, action.payload);
    case REMOVE_AIRDROP_OFFER:
      return removeAirdropOffer(state, action.payload);
    case REMOVE_POWER_UP:
      return removePowerUp(state, action.payload);
    default:
      return state;
  }
};
