/**
 * This data comes from Contentful.
 * These are fallbacks and should be kept up to date.
 * https://www.notion.so/underdogfantasy/Modifying-Landing-Page-Content-804bf16727314acc9f5585bd9b266f76
 */

export const allGamesStates = [
  'Alabama*',
  'Alaska',
  'Arizona*',
  'Arkansas*',
  'California',
  'Colorado*',
  'DC',
  'Florida*',
  'Georgia',
  'Illinois',
  'Indiana',
  'Kansas*',
  'Kentucky',
  'Maryland**',
  'Massachusetts*',
  'Michigan**',
  'Minnesota',
  'Mississippi**',
  'Missouri*',
  'Nebraska',
  'New Hampshire*',
  'New Jersey**',
  'New Mexico',
  'New York',
  'North Carolina*',
  'North Dakota',
  'Ohio**',
  'Oklahoma',
  'Oregon',
  'Pennsylvania**',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee*',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia*',
  'West Virginia*',
  'Wisconsin',
  'Wyoming*',
];

export const draftStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'DC',
  'Florida',
  'Georgia',
  'Illinois',
  'Indiana',
  'Kansas',
  'Kentucky',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Nebraska',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const pickEmChampionsStates = [
  'Alabama',
  'Arizona',
  'Arkansas',
  'Colorado',
  'Florida',
  'Kansas',
  'Massachusetts',
  'Missouri',
  'New Hampshire',
  'North Carolina',
  'Tennessee',
  'Virginia',
  'West Virginia',
  'Wyoming',
];

export const pickEmOnlyStates = [
  'Alaska',
  'California',
  'DC',
  'Georgia',
  'Illinois',
  'Indiana',
  'Kentucky',
  'Minnesota',
  'Nebraska',
  'New Mexico',
  'New York',
  'North Dakota',
  'Oklahoma',
  'Oregon',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Texas',
  'Utah',
  'Vermont',
  'Wisconsin',
];
