/* eslint-disable camelcase */
import { FeatureFlags, FeatureResponse, UserFeatureResponseKeys } from '@/interfaces/feature-flags';

const featureResponseToObject = (
  features: FeatureResponse['features']
): Record<UserFeatureResponseKeys, boolean> =>
  features.reduce(
    (acc, { key, status }) => {
      acc[key] = status;

      return acc;
    },
    {} as Record<UserFeatureResponseKeys, boolean>
  );

export const featuresAdapter = (features: FeatureResponse['features']): FeatureFlags => {
  const {
    algolia_search,
    drafts_bulk_entry_web,
    force_classic_pickem_visible,
    force_pickem_pools_visible,
    geo_comply,
    kyc_new_review_flow,
    lobby_switch,
    paysafe_deposit,
    pick_em,
    pick_em_insurance,
    reuse_entry,
    secondary_pusher_env,
    sift,
    sportsbook_pre_registration,
    swish_web,
    tertiary_pusher_env,
    ud_news,
    web_airdrops,
    web_algolia,
    web_alternate_projections,
    web_no_vultures,
    web_powerups,
    web_pick_8,
    ui_deposit_redesign,
  } = featureResponseToObject(features);

  return {
    algoliaSearch: algolia_search,
    draftsBulkEntryWeb: drafts_bulk_entry_web,
    forceClassicPickemVisible: force_classic_pickem_visible,
    forcePickemPoolsVisible: force_pickem_pools_visible,
    geoComply: geo_comply,
    kycNewReviewFlow: kyc_new_review_flow,
    lobbySwitch: lobby_switch,
    paysafeDeposit: paysafe_deposit,
    pickEm: pick_em,
    pickEmInsurance: pick_em_insurance,
    reuseEntry: reuse_entry,
    secondaryPusherEnv: secondary_pusher_env,
    sift,
    sportsbookPreRegistration: sportsbook_pre_registration,
    swishWeb: swish_web,
    tertiaryPusherEnv: tertiary_pusher_env,
    udNews: ud_news,
    webAirdrops: web_airdrops,
    webAlternateProjections: web_alternate_projections,
    webNoVultures: web_no_vultures,
    webAlgolia: web_algolia,
    webPowerUps: web_powerups,
    webPick8: web_pick_8,
    webDepositRedesign: ui_deposit_redesign,
  };
};
