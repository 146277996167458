import React from 'react';

import Icon from '@/components/atoms/icon';
import type { Boost } from '@/interfaces/pick-em';

import styles from './styles.scss';

export interface BoostIconProps {
  boost: Pick<Boost, 'displayTitle' | 'icon' | 'color' | 'textColor'>;
}

const BoostIcon = ({ boost }: BoostIconProps) => {
  return (
    <div
      title={boost.displayTitle}
      className={styles.boostIcon}
      style={{ backgroundColor: boost?.color ?? '#e0b400' }}
    >
      <Icon
        name={boost.icon}
        className={styles.icon}
        customStyles={{ fill: boost?.textColor ?? '#101010' }}
      />
    </div>
  );
};

export default BoostIcon;
