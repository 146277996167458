import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { RootState } from '@/store';

import Icon from '@/components/atoms/icon';
import FavoriteButton from '@/components/molecules/favorite-button';
import ManualScoringIcon from '@/components/molecules/manual-scoring-icon';
import PlayerDetailedAvatar from '@/components/molecules/player-detailed-avatar';
// import TokenEligibleLabel from '@/components/molecules/token-eligible-label';
import { ConstructedPickEmOverUnderLineAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { ConstructedPickEmRivalLine } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { Teams } from '@/interfaces/drafting-config';
import { Boost, PickLocation } from '@/interfaces/pick-em';
import langHelper from '@/utilities/lang-helper';

import styles from './styles.scss';

export interface PlayerAppearanceInfoProps {
  removeOverUnder?: () => void;
  constructedAppearance: ConstructedPickEmOverUnderLineAppearance;
  pickLocation: PickLocation;
  appearanceInfoLocation?: 'overUnderCell' | 'altsModal';
  teams: Teams;
  boost: Boost;
  constructedRivalLines: ConstructedPickEmRivalLine[];
}

const PlayerAppearanceInfo = (props: PlayerAppearanceInfoProps) => {
  const {
    constructedAppearance,
    teams,
    removeOverUnder,
    pickLocation,
    appearanceInfoLocation,
    boost,
    constructedRivalLines,
  } = props;

  // const powerUpOnEntry = useSelector((state: RootState) => state.pickEmEntries.selected?.powerUp);

  // const powerUpOnSelection = useSelector(
  //   (state: RootState) => {
  //     // Check if there's a powered up selection
  //     const poweredUpSelection = state.pickEmEntries.selected.selectedOverUnders.find((sOU) => {
  //       return powerUpOnEntry?.id === sOU?.powerUpId;
  //     });

  //     return poweredUpSelection;
  //   },
  //   // Check if the powered up selection changed
  //   (prev, curr) => prev?.powerUpId === curr?.powerUpId
  // );

  const playerRivalLines = useMemo(
    () =>
      constructedRivalLines?.filter((cRLA: ConstructedPickEmRivalLine) =>
        cRLA.appearanceOptions.find((appOption) => appOption.id === constructedAppearance?.id)
      ),
    [constructedAppearance, constructedRivalLines]
  );

  if (!constructedAppearance) return null;

  const { appearanceLines, match, soloGame, team, player, sport, isActive, hasLiveLines } =
    constructedAppearance;

  if (appearanceLines.length === 0) return null;

  const isSportScoredManually =
    constructedAppearance.match?.manuallyCreated || constructedAppearance.soloGame?.manuallyCreated;

  const isInactive = !isActive;

  const warningLabelEl = isSportScoredManually ? <ManualScoringIcon /> : null;

  const matchInfoText = hasLiveLines
    ? langHelper.matchTextWithScore({
        match,
        team,
        teams,
        soloGame,
        sport,
      })
    : langHelper.matchText({
        gameType: sport.gameType,
        match,
        team,
        teams,
        soloGame,
      });

  const numberOfActiveOverUnderLines = constructedAppearance?.appearanceLines?.length ?? 0;
  const numberOfActiveRivalLines = playerRivalLines?.length ?? 0;
  const totalActiveLinesForPlayer = numberOfActiveOverUnderLines + numberOfActiveRivalLines;

  return (
    <div
      className={clsx(styles.actualTopRow, {
        [styles.inactive]: isInactive,
      })}
    >
      <div className={clsx(styles.playerWrapper, { [styles.inactive]: isInactive })}>
        <PlayerDetailedAvatar
          team={team}
          player={player}
          sport={sport}
          boost={boost || null}
          borderType="none"
          backgroundStripes
          size="regular"
        />
        <div className={styles.infoContainer}>
          <div className={styles.nameAndbuttonsContainer}>
            <div className={styles.playerName} data-testid="player-name">
              <span>{langHelper.getPlayerFullName(player)}</span>
            </div>
            {/* {powerUpOnEntry?.id && !powerUpOnSelection && (
              <TokenEligibleLabel
                className={styles.eligibleLabel}
                constructedAppearance={constructedAppearance}
                pickLocation={pickLocation}
                powerUp={powerUpOnEntry}
              />
            )} */}
            <div className={styles.playerInfoButtons}>
              {warningLabelEl}
              {pickLocation === 'review' && appearanceInfoLocation !== 'altsModal' ? (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeOverUnder();
                  }}
                  data-testid="remove-button"
                  aria-label="Remove selection"
                >
                  <Icon name="clear" className={styles.removeIcon} />
                </button>
              ) : (
                <FavoriteButton
                  player={player}
                  appearanceId={constructedAppearance.id}
                  numberOfActiveLines={totalActiveLinesForPlayer}
                />
              )}
            </div>
          </div>
          <div className={styles.matchInfo} data-testid="match-info">
            <Icon className={styles.sportIcon} name={sport?.icon} />
            <p className={styles.matchInfoText}>
              {matchInfoText}
              {!hasLiveLines ? (
                <span data-chromatic="ignore">
                  {` - ${langHelper.timeToDayOrDate(match?.scheduledAt || soloGame?.scheduledAt)}`}
                </span>
              ) : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  constructedRivalLines: state.pickEmRivals.constructedPickEmRivals,
  teams: state.draftingConfig.teams,
}))(PlayerAppearanceInfo);
