/* eslint-disable camelcase */
import appearanceAdapter from '@/store/modules/constructed-slates/adapters/appearance';
import playerAdapter from '@/store/modules/constructed-slates/adapters/player';
import matchAdapter from '@/store/modules/matches/adapters/match';
import payoutOutcomeAdapter from '@/store/modules/pick-em/adapters/payout-outcome';
import powerUpAdapter from '@/store/modules/power-ups/adapters';

import { SelectedOverUnder } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { SelectedRival } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { LiveStatLines } from '@/interfaces/constructed-interfaces/constructed-slates';
import {
  AlternateProjectionsResponse,
  EntrySlips,
  EntrySlipsResponse,
  EntrySlipState,
  FeaturedLobbyResponse,
  OverUnderLines,
  PayoutOutcomeResponse,
  PickEmEntries,
  PickEmEntrySlipLimitResponse,
  PickEmEntrySlipLimits,
  PoolV1StylesResponse,
  PoolV2StylesResponse,
  RivalLineResponse,
  RivalLinesResponse,
} from '@/interfaces/pick-em';
import { RedeemablePowerUp } from '@/interfaces/power-ups';
import { UD_PICK_EM } from '@/utilities/constants';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import entrySlipAdapter from '../../adapters/entry-slip';
import pickEmEntrySlipLimitsAdapter from '../../adapters/entry-slip-limit';
import entrySlipRivalLineAdapter from '../../adapters/entry-slip-rival-line';
import overUnderAdapter from '../../adapters/over-under';
import overUnderLineAdapter from '../../adapters/over-under-lines';
import overUnderOptionAdapter from '../../adapters/over-under-option';
import poolV1StyleAdapter from '../../adapters/pool-v1-style';
import poolV2StyleAdapter from '../../adapters/pool-v2-style';
import rivalAdapter from '../../adapters/rival';
import rivalOptionAdapter from '../../adapters/rival-option';
import soloGameAdapter from '../../adapters/solo-game';

import { adjustSlipType, getBoostType } from '.';

export const setPayoutOutcomeToState = (
  state: PickEmEntries,
  { data, entryRoles }: { data: PayoutOutcomeResponse; entryRoles: string[] }
): PickEmEntries => {
  const adaptedPayoutOutcome = payoutOutcomeAdapter({ data, entryRoles });
  return {
    ...state,
    payoutOutcome: adaptedPayoutOutcome,
  };
};

export const setPoolV1Styles = (state: PickEmEntries, { data }: { data: PoolV1StylesResponse }) => {
  const poolV1StylesById = arrayToObjectIdKeys(data.pickem_pool_styles.map(poolV1StyleAdapter));

  return {
    ...state,
    poolV1Styles: poolV1StylesById,
  };
};

export const setPoolV2Styles = (state: PickEmEntries, { data }: { data: PoolV2StylesResponse }) => {
  const poolV2StylesById = arrayToObjectIdKeys(data.pickem_pool_styles.map(poolV2StyleAdapter));

  return {
    ...state,
    poolV2Styles: poolV2StylesById,
  };
};

export const setEntries = (
  state: EntrySlips,
  { data: dataResponse, pageNumber }: { data: EntrySlipsResponse; pageNumber?: number }
): EntrySlips => {
  const {
    data: {
      appearances: appearancesResponse,
      entry_slips,
      over_under_options,
      over_under_lines,
      over_unders,
      games: gamesResponse,
      players: playersResponse,
      rival_lines,
      rival_options,
      rivals: rivalsResponse,
      solo_games,
    },
    meta,
  } = dataResponse;

  const newAppearances = appearancesResponse?.map(appearanceAdapter);
  const newEntrySlips = entry_slips?.map(entrySlipAdapter);
  const newOverUnderOptions = arrayToObjectIdKeys(over_under_options?.map(overUnderOptionAdapter));
  const newOverUnderLines = arrayToObjectIdKeys(over_under_lines?.map(overUnderLineAdapter));
  const newOverUnders = arrayToObjectIdKeys(over_unders?.map(overUnderAdapter));
  const newGames = arrayToObjectIdKeys(gamesResponse?.map(matchAdapter));
  const newPlayers = arrayToObjectIdKeys(playersResponse?.map(playerAdapter));
  const newRivalLines = arrayToObjectIdKeys(rival_lines?.map(entrySlipRivalLineAdapter));
  const newRivalOptions = arrayToObjectIdKeys(rival_options?.map(rivalOptionAdapter));
  const newRivals = arrayToObjectIdKeys(rivalsResponse?.map(rivalAdapter));
  const newSoloGames = arrayToObjectIdKeys(solo_games?.map(soloGameAdapter));

  // combine with existing and remove duplicates
  const appearances =
    state.appearances && pageNumber
      ? [...state.appearances, ...newAppearances].filter(
          (appearance, ind, arr) => arr.findIndex((e) => e.id === appearance.id) === ind
        )
      : newAppearances;

  const entrySlips =
    state.entrySlips && pageNumber
      ? [...state.entrySlips, ...newEntrySlips].filter(
          (entrySlip, ind, arr) => arr.findIndex((e) => e.id === entrySlip.id) === ind
        )
      : newEntrySlips;

  const overUnderOptions =
    state.overUnderOptions && pageNumber
      ? {
          ...state.overUnderOptions,
          ...newOverUnderOptions,
        }
      : newOverUnderOptions;

  const overUnderLines =
    state.overUnderLines && pageNumber
      ? {
          ...state.overUnderLines,
          ...newOverUnderLines,
        }
      : newOverUnderLines;

  const overUnders =
    state.overUnders && pageNumber
      ? {
          ...state.overUnders,
          ...newOverUnders,
        }
      : newOverUnders;

  const games =
    state.games && pageNumber
      ? {
          ...state.games,
          ...newGames,
        }
      : newGames;

  const players =
    state.players && pageNumber
      ? {
          ...state.players,
          ...newPlayers,
        }
      : newPlayers;

  const rivalLines =
    state.rivalLines && pageNumber
      ? {
          ...state.rivalLines,
          ...newRivalLines,
        }
      : newRivalLines;

  const rivalOptions =
    state.rivalOptions && pageNumber
      ? {
          ...state.rivalOptions,
          ...newRivalOptions,
        }
      : newRivalOptions;

  const rivals =
    state.rivals && pageNumber
      ? {
          ...state.rivals,
          ...newRivals,
        }
      : newRivals;

  const soloGames =
    state.soloGames && pageNumber
      ? {
          ...state.soloGames,
          ...newSoloGames,
        }
      : newSoloGames;

  const appearanceIds = appearances.map((a) => a.id); // shouldn't change, used for pusher subscribing
  const gameIds = Object.keys(games); // shouldn't change, used for pusher subscribing
  const soloGameIds = Object.keys(soloGames);

  const liveStatLines: LiveStatLines = appearances.reduce((acc: LiveStatLines, curr) => {
    acc[curr.id] = curr.statLine;
    return acc;
  }, {});

  return {
    appearanceIds,
    gameIds,
    soloGameIds,
    entrySlips,
    overUnderOptions,
    overUnderLines,
    overUnders,
    appearances,
    games,
    players,
    rivalLines,
    rivalOptions,
    rivals,
    soloGames,
    liveStatLines,
    liveGames: games,
    meta,
    lastUpdated: new Date(),
  };
};

export const setPickEmEntries = (
  state: PickEmEntries,
  { data: dataResponse, pageNumber }: { data: EntrySlipsResponse; pageNumber?: number },
  entrySlipState: EntrySlipState
): PickEmEntries => {
  const newPickEmEntries = setEntries(state[entrySlipState], { data: dataResponse, pageNumber });

  return {
    ...state,
    [entrySlipState]: newPickEmEntries,
  };
};

export const setSelectedPickEmEntries = (
  state: PickEmEntries,
  {
    rivalLines,
    overUnderLines,
    powerUps,
  }: {
    rivalLines: RivalLinesResponse['rival_lines'];
    overUnderLines: OverUnderLines;
    powerUps: FeaturedLobbyResponse['power_ups'];
  }
) => {
  const pickEmFromLocalStorage = JSON.parse(localStorage.getItem(UD_PICK_EM));
  const { poolV2Styles } = state;

  if (!pickEmFromLocalStorage) {
    return state;
  }

  const powerUp = powerUps?.find((pU) => pU.id === pickEmFromLocalStorage?.powerUp?.id);
  const adaptedPowerUp = powerUp ? powerUpAdapter(powerUp) : null;

  const selectedOverUndersFromLocalStorage = pickEmFromLocalStorage?.selectedOverUnders;
  const selectedRivalsFromLocalStorage = pickEmFromLocalStorage?.selectedRivals;

  if (!selectedOverUndersFromLocalStorage && !selectedRivalsFromLocalStorage) {
    return {
      ...state,
      selected: {
        ...state.selected,
        powerUp: adaptedPowerUp,
      },
    };
  }

  const selectedMainLinesFromLocalStorage = selectedOverUndersFromLocalStorage.filter(
    (sOU: SelectedOverUnder) => !sOU.overUnderLineInfo?.altLineInfo?.isAlt
  );

  const selectedAltLinesFromLocalStorage = selectedOverUndersFromLocalStorage.filter(
    (sOU: SelectedOverUnder) => sOU.overUnderLineInfo?.altLineInfo?.isAlt === true
  );

  const newSelectedOverUnders = selectedMainLinesFromLocalStorage
    .map((sOUL: SelectedOverUnder) => {
      const overUnderLine = overUnderLines[sOUL.overUnderLineId];
      /** Keep the selectedOverUnder only if the line still exists
       * and its status !== 'removed'.
       */
      if (overUnderLine && overUnderLine.status !== 'removed') {
        return {
          // grab the latest overUnderLineInfo from the api response, rather than what's
          // stored in localStorage
          ...sOUL,
          overUnderLineInfo: {
            displayStat: overUnderLine.overUnder.appearanceStat.displayStat || '',
            statValue: overUnderLine.statValue || '',
            appearanceStat: overUnderLine.overUnder.appearanceStat,
            liveEvent: overUnderLine.liveEvent,
          },
        };
      }
      return null;
    })
    .filter((sOU: SelectedOverUnder) => sOU !== null);

  // filter out inactive rivals
  const filteredRivalLines: SelectedRival[] =
    pickEmFromLocalStorage?.selectedRivals?.filter((sRL: SelectedRival) =>
      rivalLines.find((line: RivalLineResponse) => line.id === sRL.id)
    ) || [];

  const boostType = selectedOverUndersFromLocalStorage
    ? getBoostType({
        selectedOverUnders: pickEmFromLocalStorage.selectedOverUnders,
      })
    : null;

  const selectedOverUndersCount = selectedOverUndersFromLocalStorage?.length || 0;
  const selectedRivalsCount = selectedRivalsFromLocalStorage?.length || 0;
  const slipType = adjustSlipType({
    boostType,
    currentSlipType: state.selected.slipType,
    selectionCount: selectedOverUndersCount + selectedRivalsCount,
  });

  const newPoolV2Style =
    poolV2Styles && Object.values(poolV2Styles || {}).find((fPS) => fPS.default);

  localStorage.setItem(
    UD_PICK_EM,
    JSON.stringify({
      ...pickEmFromLocalStorage,
      selectedOverUnders: [...newSelectedOverUnders, ...selectedAltLinesFromLocalStorage],
      selectedRivals: filteredRivalLines,
      slipType,
      poolV1Style: null,
      poolV2Style: null,
      powerUp: adaptedPowerUp,
      error: null,
      loaded: false,
    })
  );

  return {
    ...state,
    selected: {
      ...state.selected,
      selectedOverUnders: [...newSelectedOverUnders, ...selectedAltLinesFromLocalStorage],
      selectedRivals: filteredRivalLines,
      loaded: true,
      poolV2Style: newPoolV2Style,
      powerUp: adaptedPowerUp,
      slipType,
    },
  };
};

export const setSelectedAlt = (
  state: PickEmEntries,
  {
    altProjections,
    overUnderId,
    powerUps,
  }: {
    altProjections: AlternateProjectionsResponse;
    overUnderId: string;
    powerUps: RedeemablePowerUp[];
  }
) => {
  const pickEmFromLocalStorage = JSON.parse(localStorage.getItem(UD_PICK_EM));
  const { poolV2Styles } = state;

  if (!pickEmFromLocalStorage) {
    return state;
  }

  const powerUp = powerUps?.find((pU) => pU.id === pickEmFromLocalStorage?.powerUp?.id);

  const selectedOverUndersFromLocalStorage = pickEmFromLocalStorage?.selectedOverUnders.filter(
    (sOU: SelectedOverUnder) => !sOU.overUnderLineInfo?.altLineInfo?.isAlt
  );
  const selectedRivalsFromLocalStorage = pickEmFromLocalStorage?.selectedRivals;

  if (!selectedOverUndersFromLocalStorage && !selectedRivalsFromLocalStorage) {
    return {
      ...state,
      selected: {
        ...state.selected,
        powerUp,
      },
    };
  }

  const selectedAltsFromLocalStorage = pickEmFromLocalStorage?.selectedOverUnders.filter(
    (sOU: SelectedOverUnder) => sOU.overUnderLineInfo.altLineInfo?.isAlt
  );

  const newSelectedAlts = selectedAltsFromLocalStorage
    .map((sOUL: SelectedOverUnder) => {
      const overUnderLine = altProjections.projections.find(
        (altProjection) => altProjection.id === sOUL.overUnderLineId
      );
      if (
        sOUL.overUnderLineInfo.altLineInfo?.overUnderId === overUnderId &&
        altProjections.projections.length === 0
      ) {
        return null;
      }
      /** Keep the selectedOverUnder only if the line still exists
       * and its status !== 'removed'.
       */
      if (overUnderLine) {
        return {
          // grab the latest overUnderLineInfo from the api response, rather than what's
          // stored in localStorage
          ...sOUL,
          overUnderLineInfo: {
            ...sOUL.overUnderLineInfo,
            statValue: overUnderLine.stat_value || '',
          },
        };
      }
      return sOUL;
    })
    .filter((sOU: SelectedOverUnder) => sOU !== null);

  const boostType = selectedOverUndersFromLocalStorage
    ? getBoostType({
        selectedOverUnders: pickEmFromLocalStorage.selectedOverUnders,
      })
    : null;

  const selectedOverUndersCount = selectedOverUndersFromLocalStorage?.length || 0;
  const selectedRivalsCount = selectedRivalsFromLocalStorage?.length || 0;
  const slipType = adjustSlipType({
    boostType,
    currentSlipType: state.selected.slipType,
    selectionCount: selectedOverUndersCount + selectedRivalsCount,
  });

  const newPoolV2Style = poolV2Styles
    ? Object.values(poolV2Styles).find((fPS) => fPS.default)
    : null;

  localStorage.setItem(
    UD_PICK_EM,
    JSON.stringify({
      ...pickEmFromLocalStorage,
      selectedOverUnders: [
        ...selectedOverUndersFromLocalStorage,
        ...selectedRivalsFromLocalStorage,
        ...newSelectedAlts,
      ],
      slipType,
      poolV1Style: null,
      poolV2Style: null,
      powerUp,
      error: null,
      loaded: false,
    })
  );

  return {
    ...state,
    selected: {
      ...state.selected,
      selectedOverUnders: [
        ...selectedOverUndersFromLocalStorage,
        ...selectedRivalsFromLocalStorage,
        ...newSelectedAlts,
      ],
      loaded: true,
      poolV2Style: newPoolV2Style,
      powerUp,
      slipType,
    },
  };
};

export const setLimits = (
  state: PickEmEntries,
  { limits }: { limits: PickEmEntrySlipLimitResponse[] }
): PickEmEntries => {
  const adaptedPickEmEntrySlipLimits = limits.map(pickEmEntrySlipLimitsAdapter);

  const limitsBySportId: PickEmEntrySlipLimits = arrayToObjectIdKeys(
    adaptedPickEmEntrySlipLimits,
    'sportId'
  );

  return {
    ...state,
    selected: {
      ...state.selected,
      entrySlipLimits: limitsBySportId,
    },
  };
};
