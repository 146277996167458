import {
  AppearanceLine,
  ConstructedPickEmOverUnderLineAppearance,
  ConstructedPickEmOverUnderLineAppearances,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { Appearance } from '@/interfaces/constructed-interfaces/constructed-slates';
import { LineupStatuses, Sports, Teams } from '@/interfaces/drafting-config';
import { Matches } from '@/interfaces/matches';
import { Boost, FavoritePlayers, OverUnderLine, SoloGames } from '@/interfaces/pick-em';
import { SportId } from '@/interfaces/types';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

export const pickEmOverUnderAppearanceConstructor = ({
  appearance,
  games,
  lineupStatuses,
  players,
  overUnderLines: allOverUnderLines,
  soloGames,
  sports,
  teams,
}: {
  appearance: Appearance;
  games: Matches;
  lineupStatuses: LineupStatuses;
  players: FavoritePlayers;
  overUnderLines: OverUnderLine[];
  soloGames: SoloGames;
  sports: Sports;
  teams: Teams;
}) => {
  const overUnderLines = allOverUnderLines.filter(
    (oUL) => oUL.overUnder?.appearanceStat.appearanceId === appearance.id
  );

  if (overUnderLines.length === 0) {
    return null;
  }

  const team = teams[appearance.teamId];
  const player = players[appearance.playerId];
  const sport = sports[player?.sportId];
  const lineupStatus = appearance.lineupStatusId ? lineupStatuses[appearance.lineupStatusId] : null;
  const match = appearance.matchType === 'Game' ? games[appearance?.matchId] : null;
  const soloGame = appearance.matchType === 'SoloGame' ? soloGames[appearance?.matchId] : null;
  const byeWeek = match && match.byeWeeks ? match.byeWeeks[appearance.teamId] : null;

  const sortedOverUnderLines = overUnderLines.toSorted((a, b) => a.rank - b.rank);

  // The following block of values are all derived from the sortedOverUnderLines array.
  const priorityAppearanceLines: AppearanceLine[] = [];
  const regularAppearanceLines: AppearanceLine[] = [];
  const searchKeywords: string[] = [];
  let scoringTypeId: string | undefined;
  let hasLiveLines = false;
  let boost: Boost | null = null;
  let isActive = true;
  let hasAlts = false;

  // Iterate over the sortedOverUnderLines array once.
  // Any derived values for the constructed appearance should be computed here.
  sortedOverUnderLines.forEach((line) => {
    // `scoringTypeId` is attached to the overUnder object but is based on the sport/player position.
    // So one appearance seems to have only one scoringTypeId associated with it.
    if (!scoringTypeId && line.overUnder?.scoringTypeId) {
      scoringTypeId = line.overUnder.scoringTypeId;
    }

    if (line.overUnder?.appearanceStat.displayStat) {
      searchKeywords.push(line.overUnder.appearanceStat.displayStat.toLowerCase());
    }

    if (line.liveEvent) {
      hasLiveLines = true;
    }

    if (line.hasAlternates === true) {
      hasAlts = true;
    }

    if (!boost && line.overUnder?.boost) {
      // `boost` is attached to the overUnder object, but an appearance seems to only
      // have one boost assigned to it. So we capture the boost from the first line that has it.
      boost = line.overUnder.boost;
    }

    if (line.status === 'removed' || line.status === 'closed') {
      isActive = false;
    }

    if (!line.splitLine) {
      priorityAppearanceLines.push({ id: line.id, splitLine: false, option: null });
    } else {
      const optionPriority = line.overUnder?.optionPriority;

      // `optionPriority` can be `'lower' | 'higher' | 'none' | undefined`
      // However, the fallback value should be `higher` if the provided value is not `lower`.
      // i.e. we default to `higher` if the value is `none` or `undefined`.
      priorityAppearanceLines.push({
        id: line.id,
        splitLine: true,
        option: optionPriority === 'lower' ? 'lower' : 'higher',
      });
      regularAppearanceLines.push({
        id: line.id,
        splitLine: true,
        option: optionPriority === 'lower' ? 'higher' : 'lower',
      });
    }
  });

  const resolvedAppearanceLines = [...priorityAppearanceLines, ...regularAppearanceLines];

  const constructed: ConstructedPickEmOverUnderLineAppearance = {
    id: appearance.id,
    byeWeek,
    latestNewsItemUpdatedAt: appearance.latestNewsItemUpdatedAt,
    lineupStatus,
    match,
    matchType: appearance.matchType,
    boost,
    isActive,
    expiresAt: sortedOverUnderLines[0].expiresAt,
    hasLiveLines,
    scoringTypeId,
    appearanceLines: resolvedAppearanceLines,
    player,
    projection: appearance.projection,
    positionId: appearance.positionId,
    rank: sortedOverUnderLines[0].rank,
    score: appearance.score,
    soloGame,
    sport,
    team,
    searchKeywords: searchKeywords.join(','),
    hasAlts,
  };

  return constructed;
};

export const pickEmOverUnderAppearancesConstructor = ({
  appearances,
  games,
  isAdmin,
  lineupStatuses,
  players,
  overUnderLines,
  soloGames,
  sports,
  teams,
  visibleSportIds,
}: {
  appearances: Appearance[];
  games: Matches;
  isAdmin: boolean;
  lineupStatuses: LineupStatuses;
  players: FavoritePlayers;
  overUnderLines: OverUnderLine[];
  soloGames: SoloGames;
  sports: Sports;
  teams: Teams;
  visibleSportIds: SportId[] | null;
}): ConstructedPickEmOverUnderLineAppearances => {
  const constructedAppearances: ConstructedPickEmOverUnderLineAppearance[] = [];

  appearances.forEach((appearance) => {
    const constructed = pickEmOverUnderAppearanceConstructor({
      appearance,
      games,
      lineupStatuses,
      players,
      overUnderLines,
      soloGames,
      sports,
      teams,
    });

    if (!constructed) {
      return;
    }

    if (isAdmin) {
      constructedAppearances.push(constructed);
      return;
    }

    // todo: I'm not sure this is the correct place to be filtering by visibleSportIds.
    // That seems like an operation that should be happening elsewhere.
    //
    // If the state config has been successfully loaded, filter appearances
    // by visibleSportIds.
    if (visibleSportIds && visibleSportIds.length > 0) {
      visibleSportIds.forEach((sportId) => {
        if (constructed.match?.sportId === sportId || constructed.soloGame?.sportId === sportId) {
          constructedAppearances.push(constructed);
        }
      });
    } else {
      // If there was an error fetching the state config,
      // visibleSportIds would be null, so we just return all
      // appearances so that the lobby doesn't stay frozen in
      // the loading state.
      constructedAppearances.push(constructed);
    }
  });

  constructedAppearances.sort((a, b) => a.rank - b.rank);

  const result = arrayToObjectIdKeys(constructedAppearances);

  return result;
};
