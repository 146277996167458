import { TokenAddedToEntryProperties, TokenAppliedToSelectionProperties } from '@/ampli';
import {
  SelectedOverUnder,
  SelectedSharedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { SelectedRival } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { DraftingConfig, StateConfig } from '@/interfaces/drafting-config';
import { FeatureFlags } from '@/interfaces/feature-flags';
import {
  MatchUpdateResponse,
  SoloGameUpdateResponse,
  StatLineUpdateResponse,
} from '@/interfaces/live';
import {
  AlternateProjectionsResponse,
  EntrySlipsResponse,
  FavoritePlayersResponse,
  FeaturedLobbyFavoritePlayer,
  FeaturedLobbyResponse,
  FeaturedOverUnderLinesResponse,
  OverUnderLine,
  OverUnderLines,
  OverUnderLinesResponse,
  OverUnderLineSwapResponse,
  OverUnderOption,
  PayoutOutcomeRequest,
  PayoutOutcomeResponse,
  PickEmEntrySlipLimitResponse,
  PickEmSlipType,
  PickLocation,
  PoolV1StylesResponse,
  PoolV2StylesResponse,
  RivalLinesResponse,
} from '@/interfaces/pick-em';
import { PowerUpInventoryUpdatedResponse, RedeemablePowerUp } from '@/interfaces/power-ups';
import { SportId } from '@/interfaces/types';
import { Role } from '@/interfaces/user';

export const GET_PICK_EM = 'pickem/GET_PICK_EM' as const;

export const GET_PAYOUT_OUTCOME = 'pickem/GET_PAYOUT_OUTCOME' as const;
export const SET_PAYOUT_OUTCOME = 'pickem/SET_PAYOUT_OUTCOME' as const;

export const SET_ENTRY_SLIP_LIMIT = 'pickem/SET_ENTRY_SLIP_LIMIT' as const;

export const SET_PICK_EM_OVER_UNDER_LINES = 'pickem/SET_PICK_EM_OVER_UNDER_LINES' as const;

export const SET_PICK_EM_RIVAL_LINES = 'pickem/SET_PICK_EM_RIVAL_LINES' as const;

export const REMOVE_PICK_EM_OVER_UNDER_LINE = 'pickem/REMOVE_PICK_EM_OVER_UNDER_LINE' as const;

export const REMOVE_PICK_EM_RIVAL_LINE = 'pickem/REMOVE_PICK_EM_RIVAL_LINE' as const;

export const SET_PICK_EM_FEATURED_LOBBY = 'pickem/SET_PICK_EM_FEATURED_LOBBY' as const;

export const GET_LIVE_PICK_EM_ENTRIES = 'pickem/GET_LIVE_PICK_EM_ENTRIES' as const;
export const SET_LIVE_PICK_EM_ENTRIES = 'pickem/SET_LIVE_PICK_EM_ENTRIES' as const;

export const GET_SETTLED_PICK_EM_ENTRIES = 'pickem/GET_SETTLED_PICK_EM_ENTRIES' as const;
export const SET_SETTLED_PICK_EM_ENTRIES = 'pickem/SET_SETTLED_PICK_EM_ENTRIES' as const;
export const RESET_SETTLED_PICK_EM_ENTRIES = 'pickem/RESET_SETTLED_PICK_EM_ENTRIES' as const;

export const GET_POOL_V1_STYLES = 'pickem/GET_POOL_V1_STYLES' as const;
export const SET_POOL_V1_STYLES = 'pickem/SET_POOL_V1_STYLES' as const;

export const GET_POOL_V2_STYLES = 'pickem/GET_POOL_V2_STYLES' as const;
export const SET_POOL_V2_STYLES = 'pickem/SET_POOL_V2_STYLES' as const;

export const GET_PICK_EM_POOL_LEADERBOARD = 'pickem/GET_PICK_EM_POOL_LEADERBOARD' as const;
export const GET_PICK_EM_POOL_USER_LEADERBOARD =
  'pickem/GET_PICK_EM_POOL_USER_LEADERBOARD' as const;
export const SET_PICK_EM_POOL_LEADERBOARD = 'pickem/SET_PICK_EM_POOL_LEADERBOARD' as const;
export const SET_PICK_EM_POOL_USER_LEADERBOARD =
  'pickem/SET_PICK_EM_POOL_USER_LEADERBOARD' as const;
export const RESET_PICK_EM_POOL_LEADERBOARD = 'pickem/RESET_PICK_EM_POOL_LEADERBOARD' as const;
export const RESET_PICK_EM_POOL_USER_LEADERBOARD =
  'pickem/RESET_PICK_EM_POOL_USER_LEADERBOARD' as const;

export const GET_PICK_EM_ENTRIES = 'pickem/GET_PICK_EM_ENTRIES' as const;
export const SET_PICK_EM_ENTRIES = 'pickem/SET_PICK_EM_ENTRIES' as const;

// Alt Projections
export const GET_ALTERNATE_PROJECTIONS = 'pickem/GET_ALTERNATE_PROJECTIONS' as const;
export const SET_ALTERNATE_PROJECTIONS = 'pickem/SET_ALTERNATE_PROJECTIONS' as const;
export const CHOOSE_ALTERNATE_PROJECTION = 'pickem/CHOOSE_ALTERNATE_PROJECTION' as const;
export const CLEAR_ALTERNATE_PROJECTIONS_FROM_LINE =
  'pickem/CLEAR_ALTERNATE_PROJECTIONS_FROM_LINE' as const;

// Favorites
export const ADD_FAVORITE_PICK_EM_PLAYER = 'pickem/ADD_FAVORITE_PICK_EM_PLAYER' as const;
export const REMOVE_FAVORITE_PICK_EM_PLAYER = 'pickem/REMOVE_FAVORITE_PICK_EM_PLAYER' as const;

// Search
export const SET_SEARCH_TERM = 'pickem/SET_SEARCH_TERM' as const;

// Live updates
export const UPDATE_LIVE_PICK_EM_STAT_LINE = 'pickem/UPDATE_LIVE_PICK_EM_STAT_LINE' as const;
export const UPDATE_LIVE_PICK_EM_GAME = 'pickem/UPDATE_LIVE_PICK_EM_GAME' as const;
export const UPDATE_LIVE_PICK_EM_SOLO_GAME = 'pickem/UPDATE_LIVE_PICK_EM_SOLO_GAME' as const;

// Updates
export const ACCEPT_OVER_UNDER_LINE_SWAP = 'pickem/ACCEPT_OVER_UNDER_LINE_SWAP' as const;
// these are used twice, for the lobby and for selected picks
export const UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS =
  'pickem/UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS' as const;
export const SWAP_PICK_EM_OVER_UNDER_LINE = 'pickem/SWAP_PICK_EM_OVER_UNDER_LINE' as const;
export const SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE =
  'pickem/SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE' as const;
export const UPDATE_PICK_EM_LIVE_EVENT_STAT = 'pickem/UPDATE_PICK_EM_LIVE_EVENT_STAT' as const;

// Making selections
export const SET_INITIAL_PICK_EM_SELECTIONS = 'pickem/SET_INITIAL_SELECTIONS' as const;
export const SET_INITIAL_ALT_SELECTION = 'pickem/SET_INITIAL_ALT_SELECTION' as const;
export const UPDATE_SELECTED_OVER_UNDERS = 'pickem/UPDATE_SELECTED_OVER_UNDERS' as const;
export const UPDATE_SELECTED_RIVALS = 'pickem/UPDATE_SELECTED_RIVALS' as const;
export const ACCEPT_SELECTED_OVER_UNDER_LINE_SWAP =
  'pickem/ACCEPT_SELECTED_OVER_UNDER_LINE_SWAP' as const;
export const REMOVE_SELECTED_ERROR = 'pickem/REMOVE_ERROR' as const;
export const REMOVE_SELECTED_BOOST = 'pickem/REMOVE_SELECTED_BOOST' as const;
export const CLEAR_PICK_EM_ENTRY_SLIP = 'pickem/CLEAR_PICK_EM_ENTRY_SLIP' as const;
export const UPDATE_PICKS_FROM_SHARE_SLIP = 'pickem/UPDATE_PICKS_FROM_SHARE_SLIP' as const;

export const TOGGLE_PICK_EM_INSURANCE = 'pickem/TOGGLE_PICK_EM_INSURANCE' as const;
export const UPDATE_GAMEPLAY_SETTINGS = 'pickem/UPDATE_GAMEPLAY_SETTINGS' as const;

export const SET_POWER_UP_ON_ENTRY = 'pickem/SET_POWER_UP_ON_ENTRY' as const;
export const SET_POWER_UP_ON_SELECTED_OVER_UNDER =
  'pickem/SET_POWER_UP_ON_SELECTED_OVER_UNDER' as const;
export const REMOVE_POWER_UP = 'pickem/REMOVE_POWER_UP' as const;

export const REMOVE_AIRDROP_OFFER = 'pickem/REMOVE_AIRDROP_OFFER' as const;

// Actions
export const getPickEm = (payload: {
  draftingConfig: DraftingConfig;
  pickEmStateConfig: StateConfig['pickEm'];
  roles: Role[];
  isAlternateProjectionsEnabled: boolean;
}) => ({ type: GET_PICK_EM, payload });

export const getSlipPayoutOutcome = (payload: {
  options: PayoutOutcomeRequest[];
  entryRoles: string[];
  isWebPick8Enabled: boolean;
  powerUpId: string;
}) => ({
  type: GET_PAYOUT_OUTCOME,
  payload,
});

export const setSlipPayoutOutcome = (payload: {
  data: PayoutOutcomeResponse;
  entryRoles: string[];
}) => ({
  type: SET_PAYOUT_OUTCOME,
  payload,
});

export const setEntrySlipLimits = (payload: { limits: PickEmEntrySlipLimitResponse[] }) => ({
  type: SET_ENTRY_SLIP_LIMIT,
  payload,
});

export const setPickEmOverUnderLines = (payload: {
  preGameOverUnderLines: OverUnderLinesResponse;
  liveOverUnderLines: OverUnderLinesResponse;
  featuredOverUnders: FeaturedOverUnderLinesResponse;
  draftingConfig: DraftingConfig;
  featuredLobby: FeaturedLobbyResponse;
  pickEmStateConfig: StateConfig['pickEm'];
  roles: Role[];
}) => ({ type: SET_PICK_EM_OVER_UNDER_LINES, payload });

export const setPickEmRivalLines = (payload: {
  data: RivalLinesResponse;
  favoritePlayers: FavoritePlayersResponse['favorite_players'];
  draftingConfig: DraftingConfig;
  pickEmStateConfig: StateConfig['pickEm'];
  roles: Role[];
}) => ({ type: SET_PICK_EM_RIVAL_LINES, payload });

export const removePickEmOverUnderLine = (payload: { data: string[] }) => ({
  type: REMOVE_PICK_EM_OVER_UNDER_LINE,
  payload,
});

export const removeSelectedPickEmBoost = (payload: { data: string[] }) => ({
  type: REMOVE_SELECTED_BOOST,
  payload,
});

export const removePickEmRivalLine = (payload: { data: string[] }) => ({
  type: REMOVE_PICK_EM_RIVAL_LINE,
  payload,
});

export const setPickEmFeaturedLobby = (payload: {
  data: FeaturedLobbyResponse;
  pickEmStateConfig: StateConfig['pickEm'];
  draftingConfig: DraftingConfig;
  rivalLines: RivalLinesResponse['rival_lines'];
  roles: Role[];
}) => ({
  type: SET_PICK_EM_FEATURED_LOBBY,
  payload,
});

export const getLivePickEmEntries = (payload: { page?: number }) => ({
  type: GET_LIVE_PICK_EM_ENTRIES,
  payload,
});

export const setLivePickEmEntries = (payload: {
  data: EntrySlipsResponse;
  pageNumber?: number;
}) => ({
  type: SET_LIVE_PICK_EM_ENTRIES,
  payload,
});

export const getPickEmPoolLeaderboard = (payload: { page?: number; pickEmPoolId: string }) => ({
  type: GET_PICK_EM_POOL_LEADERBOARD,
  payload,
});

export const getPickEmPoolUserLeaderboard = (payload: { entrySlipId: string }) => ({
  type: GET_PICK_EM_POOL_USER_LEADERBOARD,
  payload,
});

export const resetPickEmPoolLeaderboard = () => ({ type: RESET_PICK_EM_POOL_LEADERBOARD });

export const resetPickEmPoolUserLeaderboard = () => ({ type: RESET_PICK_EM_POOL_USER_LEADERBOARD });

export const setPickEmPoolLeaderboard = (payload: { data: EntrySlipsResponse }) => ({
  type: SET_PICK_EM_POOL_LEADERBOARD,
  payload,
});

export const setPickEmPoolUserLeaderboard = (payload: { data: EntrySlipsResponse }) => ({
  type: SET_PICK_EM_POOL_USER_LEADERBOARD,
  payload,
});

export const getSettledPickEmEntries = (payload: { page?: number }) => ({
  type: GET_SETTLED_PICK_EM_ENTRIES,
  payload,
});

export const setSettledPickEmEntries = (payload: {
  data: EntrySlipsResponse;
  pageNumber?: number;
}) => ({
  type: SET_SETTLED_PICK_EM_ENTRIES,
  payload,
});

export const resetSettledPickEmEntries = () => ({ type: RESET_SETTLED_PICK_EM_ENTRIES });

export const updatePickEmLiveEventStat = (payload: {
  data: StatLineUpdateResponse;
  appearanceId: string;
}) => ({ type: UPDATE_PICK_EM_LIVE_EVENT_STAT, payload });

export const acceptOverUnderLineSwap = (payload: { overUnderLineIds: string[] }) => ({
  type: ACCEPT_OVER_UNDER_LINE_SWAP,
  payload,
});

// Alt Projections

export const getAlternateProjections = (payload: {
  overUnderId: string;
  // The optional params below are used only when we want
  // to load the alts from local storage into the entry slip.
  loadPicksFromLocalStorage?: boolean;
  alternateLineIdToChoose?: string;
  alternateOptionToChoose?: OverUnderOption;
  constructedAppearanceId?: string;
}) => ({
  type: GET_ALTERNATE_PROJECTIONS,
  payload,
});

export const setAlternateProjections = (payload: {
  altProjectionsResponse: AlternateProjectionsResponse;
  // The optional params below are used only when we want
  // to load the alts from local storage into the entry slip.
  alternateLineIdToChoose?: string;
  alternateOptionToChoose?: OverUnderOption;
  constructedAppearanceId?: string;
}) => ({
  type: SET_ALTERNATE_PROJECTIONS,
  payload,
});

export const chooseAlternateProjection = (payload: {
  selectedAlternateLineId: string;
  selectedOptionId: string;
}) => ({
  type: CHOOSE_ALTERNATE_PROJECTION,
  payload,
});

export const clearAlternateProjectionsFromLine = (payload: { overUnderLineId: string }) => ({
  type: CLEAR_ALTERNATE_PROJECTIONS_FROM_LINE,
  payload,
});

// Favorites

export const addFavoritePickEmPlayer = (payload: { player: FeaturedLobbyFavoritePlayer }) => ({
  type: ADD_FAVORITE_PICK_EM_PLAYER,
  payload,
});

export const removeFavoritePickEmPlayer = (payload: { playerId: string }) => ({
  type: REMOVE_FAVORITE_PICK_EM_PLAYER,
  payload,
});

// Search

export const setSearchTerm = (payload: { searchTerm: string }) => ({
  type: SET_SEARCH_TERM,
  payload,
});

// Live updates

export const swapPickEmOverUnderLine = (payload: { data: OverUnderLineSwapResponse[] }) => ({
  type: SWAP_PICK_EM_OVER_UNDER_LINE,
  payload,
});

export const swapFinishedPickEmOverUnderLine = (payload: { overUnderLineIds: string[] }) => ({
  type: SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE,
  payload,
});

export const updateLivePickEmStatLine = (payload: { data: StatLineUpdateResponse }) => ({
  type: UPDATE_LIVE_PICK_EM_STAT_LINE,
  payload,
});

export const updateLivePickEmGame = (payload: { data: MatchUpdateResponse }) => ({
  type: UPDATE_LIVE_PICK_EM_GAME,
  payload,
});

export const updateLivePickEmSoloGame = (payload: { data: SoloGameUpdateResponse }) => ({
  type: UPDATE_LIVE_PICK_EM_SOLO_GAME,
  payload,
});

export const updatePickEmOverUnderLineStatus = (payload: {
  statusType: 'suspended' | 'unsuspended';
  overUnderLineIds: string[];
}) => ({ type: UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS, payload });

// Set selections on page load from local storage.
export const setInitialPickEmSelections = (payload: {
  rivalLines: RivalLinesResponse['rival_lines'];
  overUnderLines: OverUnderLines;
  powerUps: FeaturedLobbyResponse['power_ups'];
}) => ({ type: SET_INITIAL_PICK_EM_SELECTIONS, payload });

export const setInitialAltSelection = (payload: {
  altProjections: AlternateProjectionsResponse;
  overUnderId: string;
  powerUps: RedeemablePowerUp[];
}) => ({ type: SET_INITIAL_ALT_SELECTION, payload });

export interface UpdateSelectedArgs<T extends SelectedOverUnder | SelectedRival> {
  action: 'remove' | 'add';
  selection: T;
  isPickEmFeatureEnabled: FeatureFlags['pickEm'];
  lobbyName?: SportId;
  pickLocation: PickLocation;
  minInsuredPicks: number;
  maxUserPicks: number;
  overUnderLine?: OverUnderLine;
}

export const updateSelectedOverUnders = (payload: UpdateSelectedArgs<SelectedOverUnder>) => ({
  type: UPDATE_SELECTED_OVER_UNDERS,
  payload,
});

export const updateSelectedRivals = (payload: UpdateSelectedArgs<SelectedRival>) => ({
  type: UPDATE_SELECTED_RIVALS,
  payload,
});

export const acceptSelectedOverUnderLineSwap = (payload: { optionIds: string[] }) => ({
  type: ACCEPT_SELECTED_OVER_UNDER_LINE_SWAP,
  payload,
});

export const removeSelectedPickEmError = () => ({ type: REMOVE_SELECTED_ERROR });

export const updateSelectedPicksFromSlip = (payload: {
  selectedOverUnders: SelectedSharedOverUnder[];
  selectedRivals: SelectedRival[];
}) => ({ type: UPDATE_PICKS_FROM_SHARE_SLIP, payload });

export const clearPickEmEntrySlip = () => ({ type: CLEAR_PICK_EM_ENTRY_SLIP });

export const togglePickEmInsurance = (payload: {
  slipType: PickEmSlipType;
  minInsuredPicks: number;
}) => ({ type: TOGGLE_PICK_EM_INSURANCE, payload });

export const updateGameplaySettings = (payload: {
  payoutDefault: string;
  autoAcceptProjectionChanges: boolean;
  userPromptedAt: string;
}) => ({
  type: UPDATE_GAMEPLAY_SETTINGS,
  payload,
});

export const getPoolV1Styles = () => ({ type: GET_POOL_V1_STYLES });

export const setPoolV1Styles = (payload: { data: PoolV1StylesResponse }) => ({
  type: SET_POOL_V1_STYLES,
  payload,
});

export const getPoolV2Styles = () => ({ type: GET_POOL_V2_STYLES });

export const setPoolV2Styles = (payload: { data: PoolV2StylesResponse }) => ({
  type: SET_POOL_V2_STYLES,
  payload,
});

export const setPowerUpOnEntry = (payload: {
  action: 'add' | 'apiRemove' | 'remove';
  location: TokenAddedToEntryProperties['location'];
  powerUp: RedeemablePowerUp;
  reason?: PowerUpInventoryUpdatedResponse['reason'];
}) => ({
  type: SET_POWER_UP_ON_ENTRY,
  payload,
});

export const setPowerUpOnSelectedOverUnder = (payload: {
  action: 'add' | 'remove';
  location: TokenAppliedToSelectionProperties['location'];
  overUnderLineId: string;
}) => ({
  type: SET_POWER_UP_ON_SELECTED_OVER_UNDER,
  payload,
});

export const removeAirdropOffer = (payload: { airdropOfferId: string }) => ({
  type: REMOVE_AIRDROP_OFFER,
  payload,
});

export const removePowerUp = (payload: { data: PowerUpInventoryUpdatedResponse }) => {
  return {
    type: REMOVE_POWER_UP,
    payload,
  };
};
